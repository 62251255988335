import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import UserTable from 'components/Quick_Installation/Creating_User_Accounts/userTable';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Creating_User_Accounts/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "User Management on your Camera",
  "path": "/Quick_Installation/Creating_User_Accounts/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Secure the Access to your IP Camera",
  "image": "./QI_SearchThumb_CreatingUserAccounts.png",
  "social": "/images/Search/QI_SearchThumb_CreatingUserAccounts.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Creating_User_Accounts_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='User Management on your Camera' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='Secure the Access to your IP Camera' image='/images/Search/QI_SearchThumb_CreatingUserAccounts.png' twitter='/images/Search/QI_SearchThumb_CreatingUserAccounts.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Benutzerverwaltung/' locationFR='/fr/Quick_Installation/Creating_User_Accounts/' crumbLabel="User Management" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "user-management-on-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#user-management-on-your-camera",
        "aria-label": "user management on your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`User Management on your Camera`}</h1>
    <h2 {...{
      "id": "secure-the-access-to-your-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#secure-the-access-to-your-ip-camera",
        "aria-label": "secure the access to your ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Secure the Access to your IP Camera`}</h2>
    <p>{`In order to access the user menu please click `}<em parentName="p">{`System`}</em>{` in the web interface of your IP camera and then choose the sub menu `}<em parentName="p">{`Users`}</em>{` as shown below. We always recommend that you to set a personal password after setting up your camera. This way you make sure no one can access the video stream besides the people you grand access to. The password should consist of BIG and small letters and numbers. Please make sure the password is `}<strong parentName="p">{`not`}</strong>{` longer than 12 characters in case of the VGA cameras or 30 characters in case of a HD camera. Special characters are possible but please check the characters which are `}<strong parentName="p">{`not`}</strong>{` supported. After submitting the set password will be sent to the camera. After that you can sign back in with your newly created password.`}</p>
    <h4 {...{
      "id": "your-camera-offers-you-three-different-access-levels",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#your-camera-offers-you-three-different-access-levels",
        "aria-label": "your camera offers you three different access levels permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your camera offers you three different access levels`}</h4>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "1080p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1080p-cameras",
        "aria-label": "1080p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/875cd3415eb1902e415c60e4e5e675be/ea64c/Benutzerkonten_fullHD2_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACe0lEQVQoz02Sz0sUYRjHB4ouHWwrCNRcs7U0akOiwHVNRY0w/4HMPOShQwXu7Ow77yzYrlC2m5g/UkkhRHd2S8k26lCotfeopEPRqUvEukQ7arPNr33fJ94ZlD68DDOH73w/z/u+nCAICCERYxwSgneGb81lbs6+vTG72juzcm1muXdmhb1ML1+ffdc39So8NM3zfe3tF7xer8/n40JIRCIWsSQE+YnHc2s5/f1PdS2nf1zXP62zp7M+ZLVvmxC7P9be1OjzN9TU1p7yejkBYQFhLEk8zyeTMgChxAQAWrSIpQODsq+iBQAPo5G9HLfP5aquOXHUc4wLsbAohcM8zyfkBAUwTJMCqIW/v/OKphumZZmWpensRw8G7+3ZtbveV+8/31RZ5bHDIVHErDmRSFBKTZM1W5al6zohhNpYFmseHRk57HJ1dFxsammpcFc62iKWwjthSqkTVlVV20ZVVQAYHxt3l5S0NDc2t7ZVuI9wIsah7bAsywDgNGualsvlFEXZslEUBQDG4vGyAwf9/obm1jamjbEUtLX7ArycSBBCHENCiKZphmGYNpqmAcCj4SGPa3/d2XOn685UVR9n4ZB9VIEA03aG3NHWtykUCgAwMTriLnN7ak8eKi0vLa/gsCSxme0Nk2WZUmoYhqOdzWbz+fyfTYaysUEISaVSlzo7r/b0dHV3X+66wpoFxLQD9oY5nZTSYrHoaDs42plMJhKJxGKxQcZdWxuJyJ45mUyyO2FrU/t+fP2lvfxe+Pzlx1ZqwVjJrL5+g0QxGo3etuEkSUIiRhgjhOLx+Iv/SKfTC8+ey4tLTxcWl+bn06knU5OT/f39AwMDUZt/I30DGAI7jywAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/875cd3415eb1902e415c60e4e5e675be/e4706/Benutzerkonten_fullHD2_EN.avif 230w", "/en/static/875cd3415eb1902e415c60e4e5e675be/d1af7/Benutzerkonten_fullHD2_EN.avif 460w", "/en/static/875cd3415eb1902e415c60e4e5e675be/7f308/Benutzerkonten_fullHD2_EN.avif 920w", "/en/static/875cd3415eb1902e415c60e4e5e675be/91422/Benutzerkonten_fullHD2_EN.avif 1116w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/875cd3415eb1902e415c60e4e5e675be/a0b58/Benutzerkonten_fullHD2_EN.webp 230w", "/en/static/875cd3415eb1902e415c60e4e5e675be/bc10c/Benutzerkonten_fullHD2_EN.webp 460w", "/en/static/875cd3415eb1902e415c60e4e5e675be/966d8/Benutzerkonten_fullHD2_EN.webp 920w", "/en/static/875cd3415eb1902e415c60e4e5e675be/36c36/Benutzerkonten_fullHD2_EN.webp 1116w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/875cd3415eb1902e415c60e4e5e675be/81c8e/Benutzerkonten_fullHD2_EN.png 230w", "/en/static/875cd3415eb1902e415c60e4e5e675be/08a84/Benutzerkonten_fullHD2_EN.png 460w", "/en/static/875cd3415eb1902e415c60e4e5e675be/c0255/Benutzerkonten_fullHD2_EN.png 920w", "/en/static/875cd3415eb1902e415c60e4e5e675be/ea64c/Benutzerkonten_fullHD2_EN.png 1116w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/875cd3415eb1902e415c60e4e5e675be/c0255/Benutzerkonten_fullHD2_EN.png",
              "alt": "User Management on your Camera",
              "title": "User Management on your Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/User/"
      }}>{`User Management Overlay`}</a>{` for the 1080p Line Cameras.`}</p>
    <h3 {...{
      "id": "720p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#720p-cameras",
        "aria-label": "720p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4975b7160419441dfec34a056e02fb5c/c60c2/Benutzerkonten_HD2_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdUE3SAOh//EABsQAAICAwEAAAAAAAAAAAAAAAEDACMCEhMh/9oACAEBAAEFAli9wHIkxeNrPV6if//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAQEAAwAAAAAAAAAAAAAAAAABAhAx/9oACAEBAAY/AoydSrr/xAAZEAEAAwEBAAAAAAAAAAAAAAABABFRITH/2gAIAQEAAT8hCu7KYBgBDJY9QtNTguRzn//aAAwDAQACAAMAAAAQ4w//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRYXGx/9oACAEBAAE/ECFsonC0y9nAQYyQb+8uqwIOtMalEdK8kU3Q9E//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4975b7160419441dfec34a056e02fb5c/e4706/Benutzerkonten_HD2_EN.avif 230w", "/en/static/4975b7160419441dfec34a056e02fb5c/d1af7/Benutzerkonten_HD2_EN.avif 460w", "/en/static/4975b7160419441dfec34a056e02fb5c/7f308/Benutzerkonten_HD2_EN.avif 920w", "/en/static/4975b7160419441dfec34a056e02fb5c/88d8d/Benutzerkonten_HD2_EN.avif 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4975b7160419441dfec34a056e02fb5c/a0b58/Benutzerkonten_HD2_EN.webp 230w", "/en/static/4975b7160419441dfec34a056e02fb5c/bc10c/Benutzerkonten_HD2_EN.webp 460w", "/en/static/4975b7160419441dfec34a056e02fb5c/966d8/Benutzerkonten_HD2_EN.webp 920w", "/en/static/4975b7160419441dfec34a056e02fb5c/faa4b/Benutzerkonten_HD2_EN.webp 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4975b7160419441dfec34a056e02fb5c/e83b4/Benutzerkonten_HD2_EN.jpg 230w", "/en/static/4975b7160419441dfec34a056e02fb5c/e41a8/Benutzerkonten_HD2_EN.jpg 460w", "/en/static/4975b7160419441dfec34a056e02fb5c/4d836/Benutzerkonten_HD2_EN.jpg 920w", "/en/static/4975b7160419441dfec34a056e02fb5c/c60c2/Benutzerkonten_HD2_EN.jpg 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4975b7160419441dfec34a056e02fb5c/4d836/Benutzerkonten_HD2_EN.jpg",
              "alt": "User Management on your Camera",
              "title": "User Management on your Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/User/"
      }}>{`User Management Overlay`}</a>{` for the 720p Line Cameras.`}</p>
    <h3 {...{
      "id": "vga-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vga-cameras",
        "aria-label": "vga cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/40ebfdf6675fe208f82f07cf307009f7/29d31/Benutzerkonten_MJPEG_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHpWxhCgf/EABkQAAIDAQAAAAAAAAAAAAAAAAACARARA//aAAgBAQABBQLmsGUplf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAEgMf/aAAgBAQAGPwIxv//EABkQAAMBAQEAAAAAAAAAAAAAAAABIRFRgf/aAAgBAQABPyFKFTuGRJcRR6MP/9oADAMBAAIAAwAAABDTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAICAwEBAAAAAAAAAAAAAAERACFRYZExsf/aAAgBAQABPxB0BLCMFne44ixehCG/TEqIKrAXyI2+oAGez//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40ebfdf6675fe208f82f07cf307009f7/e4706/Benutzerkonten_MJPEG_EN.avif 230w", "/en/static/40ebfdf6675fe208f82f07cf307009f7/d1af7/Benutzerkonten_MJPEG_EN.avif 460w", "/en/static/40ebfdf6675fe208f82f07cf307009f7/70e80/Benutzerkonten_MJPEG_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/40ebfdf6675fe208f82f07cf307009f7/a0b58/Benutzerkonten_MJPEG_EN.webp 230w", "/en/static/40ebfdf6675fe208f82f07cf307009f7/bc10c/Benutzerkonten_MJPEG_EN.webp 460w", "/en/static/40ebfdf6675fe208f82f07cf307009f7/426ac/Benutzerkonten_MJPEG_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40ebfdf6675fe208f82f07cf307009f7/e83b4/Benutzerkonten_MJPEG_EN.jpg 230w", "/en/static/40ebfdf6675fe208f82f07cf307009f7/e41a8/Benutzerkonten_MJPEG_EN.jpg 460w", "/en/static/40ebfdf6675fe208f82f07cf307009f7/29d31/Benutzerkonten_MJPEG_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/40ebfdf6675fe208f82f07cf307009f7/29d31/Benutzerkonten_MJPEG_EN.jpg",
              "alt": "User Management on your Camera",
              "title": "User Management on your Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`User Management Menu for the VGA Line Cameras.`}</p>
    <h2 {...{
      "id": "default-logins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#default-logins",
        "aria-label": "default logins permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Default Logins`}</h2>
    <h3 {...{
      "id": "hd-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hd-camera-series",
        "aria-label": "hd camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HD Camera Series`}</h3>
    <ul>
      <li parentName="ul">{`Admin`}
        <ul parentName="li">
          <li parentName="ul">{`Default Username: `}<em parentName="li">{`admin`}</em></li>
          <li parentName="ul">{`Default Password: `}<em parentName="li">{`instar`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`User`}
        <ul parentName="li">
          <li parentName="ul">{`Default Username: `}<em parentName="li">{`user`}</em></li>
          <li parentName="ul">{`Default Password: `}<em parentName="li">{`instar`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`Guest`}
        <ul parentName="li">
          <li parentName="ul">{`Default Username: `}<em parentName="li">{`guest`}</em></li>
          <li parentName="ul">{`Default Password: `}<em parentName="li">{`instar`}</em></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "vga-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vga-camera-series",
        "aria-label": "vga camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Camera Series`}</h3>
    <ul>
      <li parentName="ul">{`Admin`}
        <ul parentName="li">
          <li parentName="ul">{`Default Username: `}<em parentName="li">{`admin`}</em></li>
          <li parentName="ul">{`Default Password: `}<em parentName="li">{`"blank"`}</em></li>
        </ul>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      